/** @type {import('tailwindcss').Config} */
export default {
    content: ['storyblok/**/*.{vue,js}', 'components/**/*.{vue,js}', 'pages/**/*.vue'],
    theme: {
        container: {
            center: true,
        },
        screens: {
            sm: '640px', // => @media (min-width: 640px) { ... }
            md: '768px', // => @media (min-width: 768px) { ... }
            tablet: '901px', // => @media (min-width: 901px) { ... }
            lg: '1050px', // => @media (min-width: 1024px) { ... }
        },
        fontFamily: {
            sans: ['Source Sans Pro', 'Arial', 'Helvetica', 'sans-serif'],
        },
        extend: {
            // Extends colours with new colour variables
            colors: {
                primary: '#6b3084',
                secondary: '#eae2ed',
                tertiary: '#ebebeb',
                bodypurple: '#59256c',
                bodygray: '#ebebeb',
                'brand-black': '#180B1E',
                'brand-green': '#00AA00',
                'brand-purple': '#DCCDE2',
                'brand-purple-light': '#F3EFF5',
                'l4-accent': '#eae2ed',
                handHover: '#7b4094',
                buttongreen: '#0A0;',
            },
            maxWidth: {
                5.5: '1050px',
            },
            height: {
                132: '529px',
            },
            minWidth: {
                20: '20rem',
            },
            fontSize: {
                base: '1rem',
                xl: '1.25rem',
                '2xl': '1.563rem',
                '3xl': '1.953rem',
                '4xl': '2.5rem',
                '5xl': '3rem',
            },
            gridTemplateRows: {
                7: 'repeat(7, minmax(0, 1fr))',
                8: 'repeat(8, minmax(0, 1fr))',
                9: 'repeat(9, minmax(0, 1fr))',
                10: 'repeat(10, minmax(0, 1fr))',
                11: 'repeat(11, minmax(0, 1fr))',
                12: 'repeat(12, minmax(0, 1fr))',
                13: 'repeat(13, minmax(0, 1fr))',
                14: 'repeat(14, minmax(0, 1fr))',
                15: 'repeat(15, minmax(0, 1fr))',
            },
        },
    },
};
